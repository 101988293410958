import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../../components/springworks/Navbar';
import Footer from '../../components/springworks/Footer';
import './styles.scss';

const PrivacyContent = () => {
    return (
        <>
            <div className='tnc-navbar'>
                <Navbar />
            </div>
            <div className='policy-wrapper'>
                <Navbar />
                <h1>Privacy Policy</h1>
                <div className='policy-header'>
                    <h3>Effective Date: 26-Jul-2021</h3>
                </div>
                <div className='policy-section'>
                    <h2>
                        <span>1.</span>INTRODUCTION
                    </h2>
                    <p>
                        SpringRole Inc. and its Subsidiaries hereinafter referred as Springworks (
                        <b>"Us", "We", or "Our"</b>) operates{' '}
                        <a href={'/'} target='_blank' rel='noreferrer noopener'>
                            https://springworks.in/
                        </a>{' '}
                        (the <b>"Site"</b>) and has issued this Global Data Protection and Privacy Policy (“Policy”).
                        This page informs you of our policies regarding the collection, use and disclosure of Personal
                        Information we receive from users of the Site. Please read the entire Policy carefully it forms
                        a contract between You (“<b>Customer</b>”) and Us.
                    </p>
                    <p>
                        This Policy will apply where We are Controllers or Processors of personal data. This policy is
                        applicable to Our Products (web platform, mobile applications, API based clients) and to Our
                        public Site{' '}
                        <a href={'/'} target='_blank' rel='noreferrer noopener'>
                            {' '}
                            https://springworks.in/
                        </a>
                    </p>
                    <p>
                        By using the Site or any of our services that are listed below in the policy, you agree to the
                        collection and use of information in accordance with this policy. If you are in any doubt
                        regarding the applicable standards, or have any comments or questions about this Policy, please
                        contact us at the contact details provided under this Policy.
                    </p>
                    <p>
                        You also reserve the rights and choices as to how we collect your Data and at any point in time
                        can decline to provide any Personal Information that you want. However, when such information is
                        necessary to provide you with any of our services, your refusal will restrict the usage of such
                        features or services in part or in whole. The rights that you reserve, with regards to your
                        personal information and how to exercise them have been enumerated in this policy.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>2.</span>APPLICABILITY
                    </h2>
                    <p>This Policy applies to:</p>
                    <ul>
                        <li className={'numbered'}>
                            all the Customers who are <b>(i)</b> entities or individuals, including End-Users and Users,
                            who have subscribed to our Service(s) and have agreed to the Terms and Conditions{' '}
                            <b>(ii)</b> individuals (“<b>Individuals</b>”) from whom We collect data as a Controller.
                        </li>
                        <li className={'numbered'}>
                            all the persons whose data we collect when they visit our website.
                        </li>
                        <li className={'numbered'}>
                            all the persons who register any kind of interest in our products or provide personal
                            information through our website, career section of our website, agents, etc.
                        </li>
                    </ul>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>3.</span>THE INFORMATION WE COLLECT
                    </h2>
                    <p>
                        We may require basic information which identifies you as an individual (“
                        <b>Personal Information</b>”), in order to transact business with you, on behalf of the company
                        you work for, as our customer. We may also collect the following information, depending on the
                        service being used:
                    </p>
                    <ul>
                        <li className={'numbered'}>
                            <b>Signup Information for Services:</b> When you sign up for our services, we may collect
                            your (i) Name: first name and last name, (ii) Contact information including email address
                            and telephone numbers, (iii) User names (accounts alias) and passwords according to the
                            product specifications to allow users to login and use the product, (iv) Job title or
                            description, (v) Company or organization name, (vi) Company address, including country. This
                            information may also be collected when you show interest in signing up with our business via
                            our Website and by connecting through our Agents.
                        </li>
                        <li className={'numbered'}>
                            <b>Billing and Payment information:</b> When you subscribe to our services we would collect
                            your billing information like Credit Card number, Debit card number, billing address and
                            other such information to facilitate transactions between you and us.
                        </li>
                        <li className={'numbered'}>
                            <b>Usage Information:</b>To facilitate better experience, we may also collect certain Data
                            about the usage of our services, including (i) Time zone and date/time preferences for
                            product usage, (ii) IT information required to provide access to systems and networks such
                            as IP addresses, log files and login information, encryption generation keys, (iii)
                            Information pertinent to fulfilling business transactions on a customer’s behalf, such as
                            files uploaded by a user to a product to be processed by the application’s functionality,
                            system-generated emails, (iv) Meta Data, such as logs, for usage information and activity
                            logs, with identifying characteristics such as creator or author of a transaction, names of
                            individuals who have accessed or downloaded file(s), the time file(s) were accessed, or
                            downloaded, IP addresses of users,
                        </li>
                        <li className={'numbered'}>
                            <b>Service Data:</b>While providing you with the Services, we process your Personal
                            Information as per this policy, however due to such processing we may also perform our
                            analytics. The Data collected through such analytics is used to determine your business
                            needs and usage. This allows us to provide you with improved services and respond to your
                            requests as and when they arise.
                        </li>
                        <li className={'numbered'}>
                            <b>Other systems information:</b>
                            We may collect some other system information such as product administration activity
                            including adding and deactivating users or any other system or user information that may be
                            required for compliance with any applicable law.
                        </li>
                        <li className={'numbered'}>
                            <b>Cookies and Other Usage Data:</b>
                            We may collect some Personal Information using Cookies. Some of this Data is collected
                            automatically, for example, the log Data. Other data might be generated by third party
                            Cookies which our partners may use. These Cookies and other third party tools also allow us
                            to collect data for analytics, track your movements on our websites and administer the
                            website. Lastly we may collect your contact information like your emails, account details
                            etc. third parties like Social Media platforms, however this Data is only collected after
                            you give your consent.
                        </li>
                    </ul>
                    <p>
                        We do not collect sensitive personal information such as racial or ethnic origin, political
                        opinions, religious or philosophical beliefs, or trade union membership, genetic data, or data
                        concerning health or sexual orientation, etc. This kind of Information might be sensitive and
                        personal to you as an individual. If we ever need to collect any sensitive personal information,
                        we would give you a detailed notice and would only collect after you give your consent to such
                        collection in writing.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>4</span>HOW DO WE COLLECT YOUR PERSONAL INFORMATION
                    </h2>
                    <p>Most of the Data that we collect is provided to Us by You directly. This is done when you:</p>
                    <ul>
                        <li className={'numbered'}>
                            Use our websites or tools provided there to get in touch or to chat with our Agents.
                        </li>
                        <li className={'numbered'}>
                            Place an order or get in a contractual agreement with Us to use our services.{' '}
                        </li>
                        <li className={'numbered'}>Use or visit our website via your browser’s cookies.</li>
                        <li className={'numbered'}>
                            Provide information in response to a marketing email or contact or any survey that we may
                            conduct.
                        </li>
                        <li className={'numbered'}>
                            Interact with a marketing campaign on a third party website or service.
                        </li>
                        <li className={'numbered'}>
                            Interact with the company brand(account) on any social media website like Facebook, Twitter,
                            etc.
                        </li>
                        <li className={'numbered'}>
                            Apply for a job/work/recruitment on our website or through the career section.
                        </li>
                    </ul>
                    <p>
                        In addition to the above, we may also collect some Personal Information indirectly from third
                        parties, such as our business partners, or members of your Community. Some of your Personal
                        information like IP addresses and usage patterns are also collected by inbuilt tools within our
                        services.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>5</span>PURPOSES FOR WHICH WE COLLECT AND PROCESS PERSONAL INFORMATION
                    </h2>
                    <p>We will only use such Personal Information for the purposes mentioned below.</p>
                </div>

                <div className='policy-section'>
                    <h2 className={'no-padding'}>
                        <span>5.1</span>Business Transactions with Customers
                    </h2>
                    <p>
                        Our business transactions and Contractual agreements with You means that we need to process your
                        Personal Information through our systems to provide you Our services. Thus we would process your
                        collected personal data to provide you with our services. This is one of the major purposes for
                        which we collect and store your Personal Information. Some of these processing may include
                        International Data transfers. We process Personal Information through Our global IT systems,
                        which include tools and systems that help us to administer customer accounts, orders and
                        business transactions and share information across Our systems, and with related corporate
                        entities We do such transfers after taking adequate measures. Such transfer may also be done
                        based on our legitimate interests. More details regarding such transfers is provided in Clause
                        8.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2 className={'no-padding'}>
                        <span>5.2</span>Products
                    </h2>
                    <p>
                        You provide Personal Information to Us through our “registration” page, or by placing and
                        processing orders, or by participating in a Community, in order to use the products which run on
                        Our platforms, such as:
                    </p>

                    <div className='tnc-acceptance-table'>
                        <div className='tnc-acceptance-table-header'>
                            <span>SERVICE(S)</span>
                            <span>WEBSITE(S)</span>
                        </div>
                        <div className='tnc-acceptance-table-content'>
                            <span>EngageWith</span>
                            <span>
                                <a href={'/engagewith/'} target='_blank' rel='noreferrer noopener'>
                                    https://www.springworks.in/engagewith/
                                </a>
                            </span>
                        </div>
                        <div className='tnc-acceptance-table-content'>
                            <span>Trivia</span>
                            <span>
                                <a href={'/trivia/'} target='_blank' rel='noreferrer noopener'>
                                    https://www.springworks.in/trivia/
                                </a>
                            </span>
                        </div>
                        <div className='tnc-acceptance-table-content'>
                            <span>SpringRole</span>
                            <span>
                                <a href='https://springrole.com/' target='_blank' rel='noreferrer noopener'>
                                    https://springrole.com/
                                </a>
                            </span>
                        </div>
                        <div className='tnc-acceptance-table-content'>
                            <span>SpringRecruit</span>
                            <span>
                                <a href='https://www.springrecruit.com' target='_blank' rel='noreferrer noopener'>
                                    https://www.springrecruit.com/
                                </a>
                            </span>
                        </div>
                        <div className='tnc-acceptance-table-content'>
                            <span>Springworks</span>
                            <span>
                                <a href='/' target='_blank' rel='noreferrer noopener'>
                                    https://www.springworks.in/
                                </a>
                            </span>
                        </div>
                        <div className='tnc-acceptance-table-content'>
                            <span>Springworks Blog</span>
                            <span>
                                <a href='https://www.springworks.in/blog/' target='_blank' rel='noreferrer noopener'>
                                    https://www.springworks.in/blog/
                                </a>
                            </span>
                        </div>
                        <div className='tnc-acceptance-table-content'>
                            <span>Community Wellness Initiative</span>
                            <span>
                                <a href={'/giving-back/'} target='_blank' rel='noreferrer noopener'>
                                    https://www.springworks.in/giving-back/
                                </a>
                            </span>
                        </div>
                    </div>
                    <p>
                        We use Personal Information that we collect to administer the products, platforms and services.
                        More information as to why we process Personal Information is given below.
                    </p>

                    <ul>
                        <li>
                            We collect and process your Personal information to provide services to you as per our
                            contractual agreement and related arrangements. Our product(s) provides you services and
                            solutions by processing your Personal information.{' '}
                        </li>
                        <li>
                            We collect your contact information as it enables us to provide access to customer support
                            portals or to provide customer support services to you
                        </li>
                        <li>
                            We also retain some of your Information about your visits to our websites and how you use
                            our products. This information includes how you utilize our software for analytics purposes.
                            This helps us to improve our products or to optimize them by making them more intuitive and
                            user friendly. Such retention would also help us to optimize our system operations based on
                            usage information and to enable us to develop future products and make improvements.
                        </li>
                        <li>
                            We may also process your Personal Information as per applicable export control, customs and
                            other applicable laws and regulations that are necessary to operate our systems.
                        </li>
                        <li>
                            We would also process your Personal Information on the request of any Law enforcement agency
                            or to protect other users or the general public if required. Further, we may also share your
                            Personal Information with any law enforcement agency, if we suspect that you are using our
                            services for any illegal or unlawful activity.
                        </li>
                        <li>
                            We may also collect your usage statistics and patterns. including how you utilise our
                            products for marketing purposes. Collecting this Data helps us to market to you specific
                            products and solutions.
                        </li>
                    </ul>
                </div>

                <div className='policy-section'>
                    <h2 className={'no-padding'}>
                        <span>5.2.1</span>COOKIES
                    </h2>
                    <p>
                        Cookies are small data files that are placed on your computer or mobile device when you visit a
                        website, mobile app or use an online platform. Cookies are widely used by online service
                        providers to facilitate and help to make the interaction between users and websites, mobile apps
                        and online platforms faster and easier, as well as to provide reporting information. We use
                        cookies for advertising and analytics purposes, such as to identify returning business visitors
                        and show them customized content, for more details, refer to the Cookies Policy.
                    </p>
                    <p>
                        Some of our services may have options of interacting through your social media or similar
                        services’ accounts like(facebook, google, linkedin, twitter). These features are hosted by these
                        third parties on websites directly, and they may enable these features to work. When you use
                        these services you are governed by the privacy policy of these third parties. For more
                        information as to how these third parties use Cookies, check out our Cookie Policy.
                    </p>
                    <p>
                        Most browsers allow you to turn off cookies or to receive a warning before a cookie is stored on
                        your hard drive. You can find out more about Cookies and how we use cookies in our Cookie
                        Policy.
                    </p>
                    <p>
                        If you do not wish us to make use of your Personal Information in this way, please email
                        <a href='mailto:support@springworks.in'>support@springworks.in</a>
                    </p>
                </div>

                <div className='policy-section'>
                    <h2 className={'no-padding'}>
                        <span>5.3</span>Other legitimate business interests/purposes
                    </h2>
                    <p>
                        We will process your Personal Information when it is necessary for our legitimate interests.
                        This will help us to conduct our business more effectively and efficiently.
                        <br />
                        For instance we may use your Personal information to perform analytics, train, enhance, develop,
                        support or cure any deficiency in our services. These functions help us improve our services. It
                        also allows us to provide you with a customized and personalized service.
                        <br />
                        Based on our legitimate interest we may process your personal information to prevent any misuse
                        of our services that is in breach of this Privacy Policy or Our Terms of usage.
                        <br />
                        It is also in our legitimate interest to fulfill our Contractual obligations with You and
                        provide you with agreed upon services.
                        <br />
                        We may also process your Personal Information as a response to comply with laws, or to comply
                        with any request by a law enforcement agency or a Court order as mentioned under Clause 6.3.
                        <br />
                        However these legitimate interests will not override your interests, freedoms, rights with
                        regards to your Personal Information as a User except as per the requirements and procedures
                        established by law.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>6</span>WHO WE SHARE YOUR PERSONAL INFORMATION WITH
                    </h2>
                </div>

                <div className='policy-section'>
                    <h2 className={'no-padding'}>
                        <span>6.1</span>Within Springworks
                    </h2>
                    <p>
                        Employees, contractors and agents of Springworks may be given access to Personal Information
                        which we collect, but their use will be limited to the performance of their duties and the
                        reason for processing. Our employees, contractors and agents who have access to your Personal
                        Information are required to keep that information confidential and are not permitted to use it
                        for any other purposes.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2 className={'no-padding'}>
                        <span>6.2</span>Business Partners and Service Providers
                    </h2>
                    <p>
                        If you enter into an agreement with us, you agree to certain Data Transfers to our Partners.
                        When we permit a third party to access Personal Information, we will implement appropriate
                        measures to ensure the information is used in a manner consistent with this Policy and that the
                        security and confidentiality of the information is maintained. We will never sell, rent or
                        disclose to unaffiliated third parties your Personal Information unless we have your permission
                        or are required by law to do so.
                        <br />
                        We disclose the Personal Information We collect, to the following third parties:
                    </p>

                    <ul>
                        <li>
                            Business partners for the purposes of providing services, support and products to customers
                        </li>
                        <li>
                            Service Providers who provide business services to us. We share personal information on a
                            "need to know basis" and in accordance with applicable data privacy law. However, while we
                            ensure that sharing of Data is done according to industry practices and applicable privacy
                            laws, we encourage you to check the websites and privacy notices to keep yourself informed
                            about how your data is processed.
                        </li>
                        <li>
                            Services including social media services like Facebook, Twitter etc. for marketing purposes.
                            These disclosures however, are done with your consent and you have options to opt out of
                            these disclosures.
                        </li>
                    </ul>
                    <p>
                        Some of the Service providers with whom we share your Personal Information with are: Stripe,
                        Loggly, Sentry, AWS, Slack, Tawk, Mailchimp, clearbit, New Relic, Atlassian, Sugoi Labs,
                        Atlas(Mlabs).
                        <br />
                        As the parent company Springworks provides products, support and operational services to its
                        subsidiary SpringRole India Private Limited operating out of India.
                    </p>
                    <p>
                        Further, some aspects of our services and products are processed through our subsidiary.
                        Therefore, we share your Personal Information with our Indian subsidiary SpringRole India
                        Private Limited. This allows us to provide smooth and effective services.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2 className={'no-padding'}>
                        <span>6.3</span>To third parties on Lawful Grounds
                    </h2>
                    <p>
                        We may disclose Personal Information to third parties including Law enforcement agencies and
                        Courts, when there are lawful grounds:{' '}
                    </p>

                    <ul>
                        <li>
                            To comply with our legal obligations, regulation or contract, or to respond to an
                            administrative or judicial process
                        </li>
                        <li>
                            In response to lawful requests by public authorities (including for national security or law
                            enforcement purposes)
                        </li>
                        <li>If necessary to exercise or defend against potential, threatened or actual litigation</li>
                        <li>
                            In connection with the sale, assignment or other transfer of all or part of our business, or
                        </li>
                        <li>To exercise or to defend the legal rights of the Springworks.</li>
                    </ul>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>7</span>YOUR CONTROL OF YOUR PERSONAL INFORMATION
                    </h2>
                    <p>
                        You can request correction, update and deletion of your Personal Information via e-mail sent to{' '}
                        <a href='mailto:support@springworks.in'>support@springworks.in</a>, and we will use reasonable
                        efforts to contact you regarding your request. You may also withdraw consent provided to “Us”,
                        to use your Personal information by writing us to{' '}
                        <a href='mailto:support@springworks.in'>support@springworks.in</a>. To update or delete your
                        Personal Information or correct an inaccuracy, we may ask you to verify your identity and
                        cooperate with us in our effort.
                    </p>
                    <p>
                        You may exercise any other right available to you with regards to your Personal Information by
                        contacting us at <a href='mailto:support@springworks.in'>support@springworks.in</a>. Your
                        available rights with regards to your Personal Information are provided under Clause 10 of this
                        policy.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>8</span>PROCESSING YOUR PERSONAL INFORMATION
                    </h2>
                </div>
                <div className='policy-section'>
                    <h2 className={'no-padding'}>
                        <span>8.1</span>How do We Process
                    </h2>
                    <p>
                        As the Controller of Personal Information, we will collect your Personal Information as per the
                        conditions mentioned above. We also would process your Information only for the purposes
                        mentioned above. We will implement technical, physical and administrative measures against
                        unauthorized processing of such information and against loss, destruction of, or damage to,
                        Personal Information.
                        <br />
                        Except as expressly permitted by you under this Policy, we do not own, control or direct the use
                        of Personal Information, and only access such information as reasonably necessary to provide the
                        Service(s) (including to respond to support requests), as otherwise authorized by you or as
                        required by law.
                        <br />
                        Unless we explicitly agree otherwise in writing, we will not process any sensitive Personal
                        Information on our platform.
                    </p>
                    <p>
                        For purposes of the GDPR and the Swiss Federal Act on Data Protection, we are the Controller of
                        Personal Information, whereas you are “Data Subject”. As a Data Controller we will process your
                        Personal information as per this privacy policy and the principles of GDPR.
                    </p>
                    <p>
                        Further, If you are an EEA or Switzerland-based customer, then We are also responsible for
                        compliance with the applicable data protection law as We are the “Controllers”. As the
                        controller, it shall be our responsibility to inform You about the processing, and, where
                        required, obtain necessary consent or authorization for any Personal Information that is
                        collected as part of the Personal Information through Your use of the Service(s). We will take
                        all steps reasonably necessary to ensure that the Personal Information is treated securely and
                        in accordance with this Policy. We also ensure to keep our Data practices in accordance with the
                        GDPR principles and guidelines.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2 className={'no-padding'}>
                        <span>8.2</span>International Transfer of Personal Information
                    </h2>
                    <p>
                        As the “Controller” of Data, we may need to transfer Personal Information to countries other
                        than the ones in which the information was originally collected. This is because our Affiliates,
                        Related Entities and Business Partners may be based out of a different country(ies). We would
                        only process and transfer your Personal Information to these Affiliates, Related Entities and
                        Business Partners after informing you about such transfers and after obtaining your consent.
                        Personal Information may be shared among related entities within Springworks as well.
                    </p>
                    <p>
                        As the parent company, Springworks provides products, support and operations services, and
                        centralized corporate functions to its subsidiary - SpringRole India Pvt. Ltd.
                        <br />
                        Further, to process some of our transactions, we will transfer Personal Information to our
                        servers in the United States. This is because our parent company in the United States,
                        SpringRole Inc. hosts servers or utilize third party servers and applications necessary for
                        providing you services.
                        <br />
                        In case you are a Citizen of the EU, EEA, Switzerland or the UK, all the Data transfers would
                        match the security standards as set out under the GDPR. Further, If you are a resident of
                        European Union, EEA, Switzerland or the United Kingdom and if your Data is transferred to any
                        third country, which does not comply with adequate standards of privacy as specified by GDPR
                        then such transfers would be done as per the Standard Contractual Clauses executed between the
                        parties.
                        <br />
                        With regards to any Personal Information that is transferred to the United States, we are liable
                        to regulatory enforcement powers of the US Federal Trade Commission.
                        <br />
                        In case you withdraw your consent for such transfers, we will stop the transfer of your Data to
                        our subsidiary (SpringRole India Private Limited) and other business affiliates or partners.
                        However, this may impact your User experience significantly and you may not be able to use our
                        services partially or completely.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2 className={'no-padding'}>
                        <span>8.3</span>Links to Third Party and Privacy Policy
                    </h2>
                    <p>
                        Our Website may contain links to other websites that may collect Personal Information.
                        Springworks has no control or ownership of such websites and cannot guarantee protection of your
                        Personal Information on these websites. Users are advised to read the Privacy Policy of such
                        websites carefully and proceed carefully at their own risk.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>9</span>DATA RETENTION AND TIME PERIOD
                    </h2>
                    <ul>
                        <li className={'numbered'}>
                            As a part of our business, we have to retain your Personal Information to provide you with
                            our Services till the time you are Our Customer.
                        </li>
                        <li className={'numbered'}>
                            After you cease to be our customer we will retain Personal information and your data for a
                            further 12 month period. We retain this Data for our legal obligations, legitimate interests
                            and for analytical purposes to improve our products. After the expiration of this Period we
                            will erase your Data from our storage.
                        </li>
                    </ul>
                    <p>
                        Personal Information is retained and deleted in accordance with this Privacy Policy. If you wish
                        to request that we no longer use your Collected Data, please contact us at
                        <a href='mailto:support@springworks.in'>support@springworks.in</a>. Personal Information will be
                        retained no longer than necessary in relation to the business purposes for which such Personal
                        Information is provided and to fulfill legal requirements.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>10</span>PRIVACY RIGHTS
                    </h2>
                    <p>
                        You are entitled to certain rights with regards to your Personal Information. Further, If you
                        are an individual and resident of the EEA or Switzerland, You are classified as a “Data Subject”
                        as per the GDPR. As a resident of EEA or Switzerland, you also have certain specific rights with
                        respect to your Personal Information as mentioned below. To ensure best practices, we afford
                        these rights to all our Users, or to all persons to whom this policy is applicable, even if they
                        are not a resident of European Union.
                    </p>
                    <p>
                        We make it our utmost priority to inform you and safeguard your rights with regards to your
                        Personal Information and Data. We respond to all requests we receive from individuals wishing to
                        exercise their data protection rights in accordance with applicable data protection laws. In
                        case of any query you can write to us at{' '}
                        <a href='mailto:support@springworks.in'>support@springworks.in</a>
                    </p>
                    <p>As a User:</p>
                    <ul>
                        <li>
                            You can request access to your Personal Information. This entitles you to receive a copy of
                            your Personal Information that we hold. You can also check if we are lawfully processing
                            your Personal Information as per this Policy and applicable Laws.
                        </li>
                        <li>
                            You have a right to request rectification of any of your Personal Information that we hold.
                            This enables you to correct any incorrect or inaccurate Personal Information that we may
                            possess of yours.
                        </li>
                        <li>
                            You have a right to erasure or deletion of all your Personal Information. This enables you
                            to ask us to delete any Personal information of yours that we possess.
                        </li>
                        <li>
                            If any automated processing of your Personal Information is based on your consent or a
                            contract with you, you have a right to transfer or receive a copy of your Personal
                            Information in a usable and portable format;
                        </li>
                        <li>
                            If the processing of your Personal Information is based on your consent, you can withdraw
                            consent at any time for future processing. Withdrawing your consent will not affect the
                            lawfulness of any processing we conducted prior to your withdrawal, nor will it affect
                            processing of your Personal Information conducted in reliance on lawful processing grounds
                            other than consent;
                        </li>
                        <li>
                            You can object to, or obtain a restriction of, the processing of your Personal Information
                            under certain circumstances. such as when we are relying on legitimate interests to process
                            your Personal information, or where there is any particular or unique situation which makes
                            you object to such processing of Personal Information.
                        </li>
                        <li>
                            You also have a right to request restriction for the processing of any Personal Information
                            that we possess. This enables you to ask us to suspend the processing of any of your
                            Personal information.
                        </li>
                        <li>
                            You have the right to complain to a data protection authority about our collection and use
                            of your Personal Information. For more information, please contact your local data
                            protection authority.{' '}
                        </li>
                        <li>
                            If we have collected and processed your Personal Information with your consent, then you
                            have a right to withdraw your consent at any time, by writing to us.
                        </li>
                        <li>
                            You have a right to Opt-out of any kind of marketing or advertising from Us. This allows you
                            to stop Us from sending you any kind of marketing communication about our products and
                            services.
                        </li>
                        <li>
                            You have a right to data portability which allows you to instruct us to transfer your
                            personal Data directly to you as per your request and instructions.
                        </li>
                        <li>
                            For residents of France, you can send us specific instructions regarding the use of your
                            Personal Information after your death.
                        </li>
                    </ul>
                    <p>
                        We will not charge any fees from you for exercising your rights. However, we may charge a fee
                        for administrative purposes if we find your request to be excessive, alternatively we can also
                        reject your excessive requests.
                    </p>
                    <p>
                        To make any requests with regards to your Data related rights, contact us at
                        <a href='mailto:support@springworks.in'>support@springworks.in</a>. While you reserve a right to
                        complain to a Data Protection Authority about our Data practices, we encourage you to first
                        contact us with any questions or concerns. We respond to all requests we receive from
                        individuals wishing to exercise their data protection rights in accordance with applicable data
                        protection laws.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>11</span>CALIFORNIA-RESIDENT SPECIFIC RIGHTS
                    </h2>
                    <p>
                        If you are resident of California and is a 'consumer' as defined under the California Consumer
                        Privacy Act of 2018 ("CCPA") and We are a 'business' as defined under CCPA, then the following
                        provisions applies to you:
                    </p>
                    <p>
                        Subject to the provisions of the CCPA, you have the right to request in the manner provided
                        herein, for the following:
                    </p>
                    <p>
                        <b>Right to request for information about the:</b>
                    </p>
                    <ul>
                        <li>Categories of Personal Information We have collected about you.</li>
                        <li>Specific pieces of Personal Information We have collected about you.</li>
                        <li>Categories of sources from which the Personal Information is collected.</li>
                        <li>Business or commercial purpose for collecting Personal Information.</li>
                        <li>Categories of third parties with whom the business shares Personal Information.</li>
                    </ul>
                    <p>
                        <b>Right to request for deletion of any Personal Information collected about you by Us:</b>
                    </p>
                    <p>
                        If you seek to exercise the foregoing rights to access or delete Personal Information which
                        constitutes 'personal information' as defined in CCPA, please contact us at details provided
                        under the Contact Information clause in this Policy. We respond to all requests we receive from
                        you wishing to exercise your data protection rights within a reasonable timeframe in accordance
                        with applicable data protection laws. By writing to us, you agree to receive communication from
                        us seeking information from you in order to verify you to be the consumer from whom we have
                        collected the Personal Information from and such other information as reasonably required to
                        enable us to honour your request.
                    </p>
                    <p>
                        The details and list of the mode of collection, processing and sharing of Personal Information
                        by Us is highlighted in the present Policy. We suggest you read it carefully and then proceed.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>12</span>OUR POLICY TOWARDS CHILDREN
                    </h2>
                    <p>
                        The Services are not directed to individuals under the age of 18 years, as on the date of
                        accessing/ availing the Services provided by Us. We do not knowingly collect personal
                        information from children under the age of 18 years. Any person under the age of 18 who wants to
                        use our services, needs to provide the consent of his Guardian at{' '}
                        <a href='mailto:support@springworks.in'>support@springworks.in</a>. In addition, any person
                        below the age of 13 is not allowed to use our services under any circumstances.
                    </p>
                    <p>
                        If we become aware that a child under the age of 18 years without the consent of his/her
                        Parent/Guardian or any Child under the age of 13 has provided Us with their Personal
                        Information, then in that event We will take appropriate steps to delete such information,
                        without undertaking any liability in that regard. We also encourage Parents/Guardians to monitor
                        the internet usage of their children to ensure that they don’t access our services without your
                        consent. If you become aware that a child has provided Us with their Personal Information, then
                        please contact us on the details provided under the Contact Information clause incorporated
                        under this Policy.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>13</span>SECURITY
                    </h2>
                    <p>
                        Our Company securely hosts your Data using AWS cloud Services. Your Data is stored in the United
                        States AWS Region. We are committed to ensuring that your information is secure. We have put in
                        place appropriate technical, physical and administrative procedures and protocols to safeguard
                        and secure the information we collect in order to prevent unauthorised access, modification or
                        disclosure. We have also prepared specific protocols to deal with any kind of breach should it
                        happen.
                        <br />
                        However, while we ensure to maintain all the safety standards and procedures, you acknowledge
                        that the Internet is a global environment and Our products allow users to send documents and
                        communicate with other users. We are not responsible for the data protection or privacy
                        practices or the content of other users’ sites. When your communications leave our platforms, we
                        cannot be responsible for the protection and privacy of any information which you provide while
                        visiting such sites and such sites are not governed by this Policy. You should review the data
                        protection and privacy statements applicable to such sites.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2 className={'no-padding'}>
                        <span>13.1</span>Payment Systems Security
                    </h2>
                    <p>
                        To ensure safe and secure payments, we comply with the current standards of the industry. Our
                        payment systems are compliant with ISO 27001 standards. Further, our payment systems are also
                        compliant with Payment Car Industry (PCI) Data security standards.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>14</span>UPDATES TO THIS POLICY/NOTICE
                    </h2>
                    <p>
                        Springworks reserves the right, in its discretion, to make changes to any part of the products,
                        platforms or this Policy. Springworks may change this Policy from time to time by updating this
                        page. You should check this page from time to time to ensure that you are aware of any changes.
                        This Policy is effective from 26-Jul-2021. By continuing to use the products and services, you
                        consent to this Policy as amended.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>15</span>OPT-OUT PROCEDURE
                    </h2>
                    <p>
                        Springworks may send you marketing related communications from time to time. As a User you
                        reserve a right to opt out of these marketing communications. You can opt-out of these marketing
                        communications at any time by writing to us at{' '}
                        <a href='mailto:support@springworks.in'>support@springworks.in</a>. <br />
                        You can also object to usage of your Personal Information for Analytics by writing to us at
                        <a href='mailto:support@springworks.in'>support@springworks.in</a>
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>16</span>OTHER COMMUNICATIONS
                    </h2>
                    <p>
                        If you are a Customer of Springworks, we may need to communicate certain necessary information
                        to you. This information might include product information, updates or related announcements.
                        For instance: Information regarding maintenance of any product or our website. We assure you
                        that we will send these announcements only when they are necessary. Such communications are not
                        marketing communications and you may not opt out of them. The only way to Opt-out of these is by
                        deactivating your account.
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>
                        <span>17</span>CONTACT INFORMATION
                    </h2>
                    <p>
                        If you have any questions about this Policy, or wish to make a complaint about our data handling
                        practices, you may contact:
                    </p>
                </div>

                <div className='policy-section'>
                    <h2>Data Protection Officer</h2>
                    <p>
                        SpringRole Inc, 1447 2nd Street Suite 200 Santa Monica, CA 90401 United States <br />
                        Email: <a href='mailto:support@springworks.in'>support@springworks.in</a>
                    </p>
                    <p>
                        We will investigate any complaints received in writing and do our best to resolve them with you
                        as soon as possible.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

const PrivacyHelmet = (
    <Helmet>
        <title>Privacy Policy | Springworks</title>
        <meta
            data-react-helmet='true'
            name='description'
            content='Springworks is the embodiment of our vision and ambition for building the tools and products to simplify hiring and recruiting for the recruiting future.'
        />
        <meta property='og:locale' content='en_US' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='Privacy Policy | Springworks' />
        <meta
            property='og:description'
            content='Springworks is the embodiment of our vision and ambition for building the tools and products to simplify hiring and recruiting for the recruiting future.'
        />
        <meta property='og:url' content='https://www.springworks.in/about-us/' />
        <meta property='og:site_name' content='Springworks' />
        <meta
            property='og:image'
            content='https://assets-springengage.s3.amazonaws.com/web/springworks-solving-hr-challenges.png'
        />
        <meta
            property='og:image:secure_url'
            content='https://assets-springengage.s3.amazonaws.com/web/springworks-solving-hr-challenges.png'
        />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='600' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta
            name='twitter:description'
            content='Springworks is the embodiment of our vision and ambition for building the tools and products to simplify hiring and recruiting for the recruiting future.'
        />
        <meta name='twitter:title' content='Privacy Policy | Springworks' />
        <meta name='twitter:creator' content='@springroleinc' />
        <meta
            name='twitter:image'
            content='https://assets-springengage.s3.amazonaws.com/web/springworks-solving-hr-challenges.png'
        />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='600' />
        <link rel='canonical' href='https://www.springworks.in/privacy-policy/' />
    </Helmet>
);

const PrivacyPolicy = () => {
    return (
        <>
            {PrivacyHelmet}
            <PrivacyContent />
        </>
    );
};

export default PrivacyPolicy;
